.ant-layout-header {
  background-color: #1890ff;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.ant-typography {
  margin-bottom: 0;
}

.order-info {
  background-color: #f0f2f5;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.order-status {
  margin-bottom: 20px;
}

.timeline-item {
  margin-bottom: 20px;
}

.timeline-item p {
  margin-bottom: 4px;
}

.shipping-info {
  background-color: #efefef;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.shipping-info p {
  margin-bottom: 8px;
}

.ant-layout-header {
      background-color: #fff
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.flex-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.flex-items:nth-child(2) {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  margin-left: 10px;
}